import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

type Auth0ContextWrapperProps = {
    children: React.ReactNode;
};

export const Auth0ContextWrapper: FC<Auth0ContextWrapperProps> = ({ children }) => {
    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN!}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
            authorizationParams={
                {
                    redirect_uri: `${window.location.origin}/authorize`,
                }
            }>
            {children}
        </Auth0Provider>
    );
}  