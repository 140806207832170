import { FC } from "react";
import { useUserContext } from "../../Contexts/UserContext";
import { useHeader, useLayoutContext } from "../../Contexts/LayoutContext";

export const ProfilePage: FC = () => {

    useHeader();

    const { user } = useUserContext();

    return (
        <div style={
            {
                width: "100%",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center"
            }
        }>
            <div style={
                {
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center"
                }
            }>
                <table>
                    <tbody>
                        <tr>
                            <td>First Name:</td>
                            <td>{user?.first_name}</td>
                        </tr>
                        <tr>
                            <td>Last Name:</td>
                            <td>{user?.last_name}</td>
                        </tr>
                        <tr>
                            <td>Email:</td>
                            <td>{user?.email}</td>
                        </tr>
                        <tr>
                            <td>Username:</td>
                            <td>{user?.username}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );

};