import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { SplashPage } from './Pages/SplashPage/SplashPage';
import { HomePage } from './Pages/HomePage/HomePage';
import { AuthorizePage } from './Pages/AuthorizePage/AuthorizePage';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { WelcomePage } from './Pages/WelcomePage/WelcomePage';
import { ProfilePage } from './Pages/ProfilePage/ProfilePage';

const router = createBrowserRouter([

  {
    path: "/", element: <App />, children: [
      {
        path: "",
        element: <SplashPage />
      },
      {
        path: "home",
        element: <HomePage />
      },
      {
        path: "authorize",
        element: <AuthorizePage />,
      },
      {
        path: "welcome",
        element: <WelcomePage />
      },
      {
        path: "profile",
        element: <ProfilePage />
      }
    ]
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <RouterProvider router={router} />
);
