import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, FC, useCallback } from "react";
import { useNavigate } from "react-router";
import { Header } from "../Components/Layout/Header";

type LayoutContextType = {
    setShowHeader: Dispatch<SetStateAction<boolean>>;
}

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

export const useLayout = () => useContext(LayoutContext);

type LayoutContextProviderProps = {
    children: React.ReactNode;
};

export const useLayoutContext = () => {
    const context = useContext(LayoutContext);
    if (!context) {
        throw new Error("useLayout must be used within a UserLayoutProvider");
    }
    return context;
}

export const LayoutContextProvider: FC<LayoutContextProviderProps> = ({ children }: LayoutContextProviderProps) => {

    const [showHeader, setShowHeader] = React.useState<boolean>(false);

    return (
        <LayoutContext.Provider value={{ setShowHeader }}>
            {showHeader && <Header />}
            {children}
        </LayoutContext.Provider>
    );
}

export const useHeader = () => {
    const { setShowHeader } = useLayoutContext();

    useEffect(() => {
        setShowHeader(true);
        return () => {
            setShowHeader(false);
        }
    }, [setShowHeader])

}

export const useNoHeader = () => {
    const { setShowHeader } = useLayoutContext();

    useEffect(() => {
        setShowHeader(false);
        return () => {
            setShowHeader(false);
        }
    }, [setShowHeader])
}