import { useEffect } from 'react';
import { Box } from '@mui/material';
import { useUserContext } from '../../Contexts/UserContext';
import { useHeader, useLayoutContext } from '../../Contexts/LayoutContext';

export const HomePage = () => {

  useHeader();

  const { logout } = useUserContext();

  return (
    <Box minHeight="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
      <h1>SKX</h1>
      <button onClick={logout}>Logout</button>
    </Box>
  );

};