import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import { useHeader, useNoHeader } from '../../Contexts/LayoutContext';


export const AuthorizePage = () => {

    useNoHeader();

    const { getAccessTokenSilently } = useAuth0();

    const [accessToken, setAccessToken] = React.useState<string | null>(null);

    useEffect(() => {
        const getAccessToken = async () => {
            try {
                const token = await getAccessTokenSilently();
                setAccessToken(token);
            } catch (e) {
                console.error(e);
            }
        };
        getAccessToken();
    }, []);

    return (
        <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <h1>Authorizing</h1>
        </Box>
    );
};