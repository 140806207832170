import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, FC, useCallback } from "react";
import { useNavigate } from "react-router";

type SKXSettings = {
    first_login: string
    last_login: string
}

type Profile = {
    created_at: string;
    updated_at: string;
    lastLogin: Date;
    skxSettings: SKXSettings;
}

type User = {
    id: string;
    username: string;
    email: string;
    profile: Profile;
    first_name: string;
    last_name: string;
}

type UserContextType = {
    user: User | null;
    logout: () => void

}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUser = () => useContext(UserContext);

type UserContextProviderProps = {
    children: React.ReactNode;
};

export const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useUser must be used within a UserContextProvider");
    }
    return context;
}

export const UserContextProvider: FC<UserContextProviderProps> = ({ children }: UserContextProviderProps) => {

    const navigate = useNavigate();

    const { isAuthenticated, isLoading, getAccessTokenSilently, getIdTokenClaims, logout: auth0logout } = useAuth0();

    const [user, setUser] = React.useState<User | null>(null);

    const logout = useCallback(() => {
        setUser(null);
        auth0logout({ logoutParams: { returnTo: window.location.origin } });
    }, [auth0logout]);

    useEffect(() => {
        if (!isAuthenticated || isLoading || user) return;
        const getUser = async () => {
            try {
                const tokenClaims = await getIdTokenClaims();

                if (!tokenClaims) {
                    return;
                }

                const response = await fetch("/profile/get_or_create", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${tokenClaims.__raw}`,
                    },
                });
                const user = await response.json();
                setUser(user);
                if (response.status === 200){
                    navigate("/home");
                }
                if (response.status === 201){
                    navigate("/welcome");
                }
            } catch (e) {
                auth0logout({ logoutParams: { returnTo: window.location.origin } });
                console.error(e);
            }
        };
        getUser();
    }, [isAuthenticated, isLoading, getAccessTokenSilently])

    console.log(user);

    return (
        <UserContext.Provider value={{ user, logout }}>
            {children}
        </UserContext.Provider>
    );
}