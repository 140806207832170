import { FC, useEffect } from "react";
import { useUserContext } from "../../Contexts/UserContext";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { useNoHeader } from "../../Contexts/LayoutContext";

export const WelcomePage: FC = () => {

    useNoHeader();

    const navigate = useNavigate();
    const { user } = useUserContext();

    return (
        <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <div style={{ height: "100%", width: "750px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <h1>Welcome to SKX</h1>
                <h2>Would you like to update any of the following information before you enter? (You'll be able to later in profile menu)</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>First Name:</td>
                            <td>{user?.first_name}</td>
                        </tr>
                        <tr>
                            <td>Last Name:</td>
                            <td>{user?.last_name}</td>
                        </tr>
                        <tr>
                            <td>Email:</td>
                            <td>{user?.email}</td>
                        </tr>
                        <tr>
                            <td>Username:</td>
                            <td>{user?.username}</td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <Button variant="contained" onClick={() => navigate("/profile")}>Update</Button>
                    <Button variant="contained">Nah.</Button>
                </div>
            </div>
        </div>
    );
};