import { Box, Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useNoHeader } from '../../Contexts/LayoutContext';

export const SplashPage = () => {

  useNoHeader();

  const { loginWithRedirect } = useAuth0();

  return (
    <Box minHeight="100%" width="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <h1>SKX</h1>
      <h2> If you're not a skk, go away</h2>
      <Button onClick={() => loginWithRedirect()}>Login</Button>
    </Box>
  );

};