import React from 'react';
import './App.css';

import {
  Outlet
} from "react-router-dom";
import { Auth0ContextWrapper } from './Contexts/Auth0Context';
import { UserContextProvider } from './Contexts/UserContext';
import { LayoutContextProvider } from './Contexts/LayoutContext';



function App() {
  return (
    <Auth0ContextWrapper>
      <UserContextProvider>
        <LayoutContextProvider>
          <div className="App">
            <Outlet />
          </div>
        </LayoutContextProvider>
      </UserContextProvider>
    </Auth0ContextWrapper>
  );
}

export default App;
